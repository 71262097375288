import { CamelCasedObjectHelper } from '@/types/helpers';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { camelCase } from 'lodash';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

/**
 * Concatenates multiple class values into a single class value.
 *
 * @param {ClassValue[]} inputs - An array of class values to concatenate.
 * @return {ClassValue} - The concatenated class value.
 */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

/**
 * Returns the text direction for a given locale.
 *
 * @param {string} locale - The locale for which to determine the text direction.
 * @return {"rtl" | "ltr"} - The text direction, either "rtl" for right-to-left or "ltr" for left-to-right.
 */
export const getLocaleTextDirection = (locale: string): 'rtl' | 'ltr' => {
    // List of RTL locales
    const rtlLocales = ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'];

    const localeWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0]; // "en-US" -> "en"

    return rtlLocales.includes(localeWithoutRegionCode) ? 'rtl' : 'ltr';
};

/**
 * Generates an array of numbers starting from 1 up to the specified length.
 *
 * @param {number} length - The length of the array to be generated.
 * @return {Array<number>} - An array of numbers starting from 1 up to the specified length.
 */
export const fakeArray = (length: number): Array<number> =>
    Array.from({ length }, (_, index) => index + 1);

export const pickRandomBetweenOptions = (options: any[]): number => {
    const randomIndex = Math.floor(Math.random() * options.length);
    return options[randomIndex];
};

interface IReplaceParamsProps {
    url: string;
    params: Array<string | number>;
}
export const replaceParams = (props: IReplaceParamsProps): string => {
    const { url, params } = props;
    const pattern = /{(\d+)}/g;
    const placeholderMatches = url.match(pattern);
    if (placeholderMatches) {
        const placeholdersCount = placeholderMatches.length;
        if (placeholdersCount !== params.length) {
            throw new Error('The number of parameters do not match the expected parameters in URL');
        }
    }
    if (!placeholderMatches && params.length > 0) {
        throw new Error('The number of parameters do not match the expected parameters in URL');
    }
    let result = url;
    if (params.length === 0) return url;
    for (let i = 0; i < params.length; i++) {
        result = result.replace(`{${i + 1}}`, params[i] && params[i]?.toString());
    }
    return result;
};

export const areTwoArraysEqual = (a: string[], b: string[]) =>
    a.every(v => b.includes(v)) && b.every(v => a.includes(v));

export const makeArrayCamelCase = <T = Record<string, any>>(
    inputs: Array<Record<string, any>>
): Array<CamelCasedObjectHelper<T>> =>
    inputs.map(inp => {
        if (Array.isArray(inp)) return makeArrayCamelCase(inp);
        if (typeof inp === 'object') return makeObjectCamelCase(inp);
        return inp;
    }) as unknown as Array<CamelCasedObjectHelper<T>>;

export const makeObjectCamelCase = <T extends object = object>(
    inp: Record<string, any>
): T & Record<string, any> => {
    const result = {} as any;
    for (const item in inp) result[camelCase(item)] = getCCValueHelper(inp[item]);
    return result;
};

export const getCCValueHelper = (val: any): any => {
    if (val === null) return null;
    if (Array.isArray(val)) return makeArrayCamelCase(val);
    if (typeof val === 'object') return makeObjectCamelCase(val);
    return val;
};

// utils/errorProcessor.ts
// utils/errorProcessor.ts
export function processErrorResponse(errorResponse: Record<string, any>): string[] {
    const errorMessages: string[] = [];

    for (const key in errorResponse) {
        if (Object.prototype.hasOwnProperty.call(errorResponse, key)) {
            const value = errorResponse[key];
            if (Array.isArray(value)) {
                errorMessages.push(...value);
            } else if (typeof value === 'string') {
                errorMessages.push(value);
            }
        }
    }

    return errorMessages;
}

export const isWithinTimeFrame = (
    startDate: string,
    endDate: string,
    timeFrameStartDate: string,
    timeFrameEndDate: string
): boolean => {
    const sDate = dayjs(startDate);
    const eDate = dayjs(endDate);
    const tfStartDate = dayjs(timeFrameStartDate);
    const tfEndDate = dayjs(timeFrameEndDate);

    return sDate.isAfter(tfStartDate) && eDate.isBefore(tfEndDate);
};

/******************************** Export Utilities ******************************** */

export const convertDataToCSV = <T extends Record<string, any>>(
    headers: string[],
    accessors: string[],
    data: T[]
): (string | any)[][] => {
    const csvRows = data.map(item => {
        return accessors.map(accessor => item[accessor]);
    });

    csvRows.unshift(headers);
    return csvRows;
};

export const exportToCSV = (tableData: string[][], fileName: string) => {
    const csvContent = tableData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });

    saveAs(blob, `${fileName}.csv`);
};

// export const exportToXlsx = <T extends Record<string, any>>(data: T[], fileName: string): void => {
//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet(data);
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

//     const excelBuffer = XLSX.write(workbook, {
//         bookType: 'xlsx',
//         type: 'array',
//     });

//     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

//     saveAs(blob, `${fileName}.xlsx`);
// };

interface CellStyle {
    font?: Partial<ExcelJS.Font>;
    alignment?: Partial<ExcelJS.Alignment>;
    border?: Partial<ExcelJS.Borders>;
    fill?: ExcelJS.Fill;
}

interface RowStyle {
    row: number;
    style: CellStyle;
}

interface ColumnStyle {
    col: number;
    style?: (value: ExcelJS.Cell) => CellStyle;
}

export interface IExportToXlsx {
    sheetName?: string;
    fileName?: string;
    cellStyles?: { [key: string]: CellStyle };
    rowStyles?: RowStyle[];
    columnStyles?: ColumnStyle[];
    autoFit?: boolean;
    allCellStyle?: CellStyle;
}

export const exportToXlsx = async (jsonData: any[], options: IExportToXlsx = {}) => {
    const {
        sheetName = 'Sheet1',
        fileName = 'data',
        cellStyles = {},
        rowStyles = [],
        columnStyles = [],
        autoFit = true,
        allCellStyle = {},
    } = options;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    // Add the header row
    const headerKeys = Object.keys(jsonData[0]);

    // Add the data rows
    jsonData.forEach(data => {
        const rowValues = headerKeys.map(headerKey => data[headerKey]);
        worksheet.addRow(rowValues);
    });

    // Apply column styles
    columnStyles.forEach(({ col, style }) => {
        const colObj = worksheet.getColumn(col);
        colObj.eachCell(cell => {
            const s = style(cell);
            if (s.font) cell.font = s.font;
            if (s.alignment) cell.alignment = s.alignment;
            if (s.border) cell.border = s.border;
            if (s.fill) cell.fill = s.fill;
        });
    });

    // Apply row styles
    rowStyles.forEach(({ row, style }) => {
        const rowObj = worksheet.getRow(row);
        rowObj.eachCell(cell => {
            if (style.font) cell.font = style.font;
            if (style.alignment) cell.alignment = style.alignment;
            if (style.border) cell.border = style.border;
            if (style.fill) cell.fill = style.fill;
        });
    });

    // Apply cell styles
    Object.keys(cellStyles).forEach(cellAddress => {
        const cell = worksheet.getCell(cellAddress);
        const style = cellStyles[cellAddress];
        if (style) {
            cell.font = style.font;
            cell.alignment = style.alignment;
            cell.border = style.border;
            cell.fill = style.fill;
        }
    });
    // Apply styles to all cells if allCellStyle is provided
    if (allCellStyle) {
        worksheet.eachRow(row => {
            row.eachCell(cell => {
                if (allCellStyle.font) cell.font = allCellStyle.font;
                if (allCellStyle.alignment) cell.alignment = allCellStyle.alignment;
                if (allCellStyle.border) cell.border = allCellStyle.border;
                if (allCellStyle.fill) cell.fill = allCellStyle.fill;
            });
        });
    }

    // Auto-fit column widths
    if (autoFit) {
        worksheet.columns.forEach(column => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, cell => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength + 5; // Adding 2 for padding
        });
    }

    // Write the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
};
