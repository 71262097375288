import { cn } from '@/utils/utils';
import React from 'react';

export type TTypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'bold';
interface TypographyProps {
    variant?: TTypographyVariant;
    className?: string;
    children: React.ReactNode;
}

const variantStyles = {
    h1: 'text-4xl font-bold',
    h2: 'text-3xl font-bold',
    h3: 'text-2xl font-bold',
    h4: 'text-xl font-bold',
    h5: 'text-lg font-bold',
    h6: 'text-base font-bold',
    p: 'text-base',
    span: 'text-base',
    bold: 'text-sm font-bold',
};

const Typography: React.FC<TypographyProps> = ({ variant = 'p', className, children }) => {
    const Component = variant as keyof JSX.IntrinsicElements;
    return <Component className={cn(variantStyles[variant], className)}>{children}</Component>;
};

export default Typography;
